import {
  AutocompleteInput,
  ReferenceInput,
  Create,
  SimpleForm,
  required,
  useGetIdentity,
  SelectInput,
} from 'react-admin';
import { Box } from '@mui/material';

export const TeamProfileCreate = props => {
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
  if (isLoading || !allowedAccess) return null;
  return (
    <Create redirect="list" {...props}>
      <SimpleForm>
        <Box display={{ sm: 'block', md: 'flex' }}>
          <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
            <ReferenceInput
              label="resources.teams_profiles.fields.profile_id"
              source="profile_id"
              reference="profiles"
              fullWidth
            >
              <AutocompleteInput
                noOptionsText="-"
                shouldRenderSuggestions={(val: any) => {
                  return val.trim().length > 2;
                }}
                filterToQuery={(searchText: any) => ({
                  "email@ilike": `%${searchText}%`,
                })}
                optionText="email" />
            </ReferenceInput>
            <ReferenceInput
              label="resources.teams_profiles.fields.team_id"
              source="team_id"
              reference="teams"
              validate={[required()]}
              sort={{ field: "name", order: "ASC" }}
              filter={{ currentSeason: true }}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Box>
        </Box>
      </SimpleForm>
    </Create>
  );
};
