import { Admin, CustomRoutes, DataProvider } from 'react-admin';
import { Resource } from '@s-graber/ra-rbac';
import { Route, BrowserRouter } from 'react-router-dom';
// import CustomRouteLayout from './customRouteLayout';
import CustomRouteNoLayout from './customRouteNoLayout';
import { postgrestRestDataProviderWithRefresh } from './postgrestRestProvider';
import ScpLayout from './layout';
import ScpTheme from './theme';
import { ClubList, ClubEdit, ClubCreate, ClubIcon } from './pages/clubs';
import { i18nProvider } from './i18n';
import { Provider } from 'react-supabase';
import { CoachList, CoachEdit, CoachCreate, CoachIcon } from './pages/coaches';
import { authProvider, myMSALObj, wrappedAuthProvider } from "./authProviderAzure";
import { LoginPage } from "ra-auth-msal";
import {
    PlayerList,
    PlayerEdit,
    PlayerCreate,
    PlayerIcon,
} from './pages/players';
import {
    ConsultancyCreate,
    ConsultancyEdit,
    ConsultancyIcon,
    ConsultancyList,
} from './pages/consultancies';
import {
    SeasonCreate,
    SeasonEdit,
    SeasonIcon,
    SeasonList,
} from './pages/seasons';
import {
    AgeclassCreate,
    AgeclassEdit,
    AgeclassIcon,
    AgeclassList,
} from './pages/ageclasses';
import {
    RepteamCreate,
    RepteamEdit,
    RepteamIcon,
    RepteamList,
} from './pages/repteams';
import {
    LeagueCreate,
    LeagueEdit,
    LeagueIcon,
    LeagueList,
} from './pages/leagues';
import {
    PositionCreate,
    PositionEdit,
    PositionIcon,
    PositionList,
} from './pages/positions';
import {
    LineupCreate,
    LineupEdit,
    LineupIcon,
    LineupList,
} from './pages/lineups';
import {
    TextCreate,
    TextEdit,
    TextIcon,
    TextList,
    TextShow,
} from './pages/texts';
import { TeamCreate, TeamEdit, TeamIcon, TeamList } from './pages/teams';
import {
    SquadplayerCreate,
    SquadplayerIcon,
    SquadplayerList,
} from './pages/squadplayers';
import {
    PracticeCreate,
    PracticeEdit,
    PracticeIcon,
    PracticeList,
} from './pages/practices';
import {
    TrainingCreate,
    TrainingEdit,
    TrainingIcon,
    TrainingList,
} from './pages/trainings';
import { MatchCreate, MatchEdit, MatchIcon, MatchList } from './pages/matches';
import {
    AbsenceCreate,
    AbsenceEdit,
    AbsenceIcon,
    AbsenceList,
} from './pages/absences';
import { CallCreate, CallEdit, CallIcon, CallList } from './pages/calls';
import {
    ReviewCreate,
    ReviewEdit,
    ReviewIcon,
    ReviewList,
} from './pages/reviews';
import {
    ScoutingCreate,
    ScoutingEdit,
    ScoutingIcon,
    ScoutingList,
} from './pages/scoutings';
import {
    SprintvalueCreate,
    SprintvalueEdit,
    SprintvalueIcon,
    SprintvalueList,
} from './pages/sprintvalues';
import {
    TransferCreate,
    TransferEdit,
    TransferIcon,
    TransferList,
} from './pages/transfers';
import {
    ApplicationIcon,
    ApplicationList,
    ApplicationShow,
} from './pages/applications';
import { CalendarIcon, CalendarList } from './pages/calendar';
import { PhysicalList, PhysicalIcon } from './pages/physicals';
import { DfbnetEmailList, DfbnetEmailIcon } from './pages/dfbnet_emails';
import { supabase } from './supabase';
import { Home } from './pages/home';
// import ScpLoginPage from './layout/ScpLoginPage';
import { MsalProvider } from '@azure/msal-react';
import { addEventsForMutations } from '@s-graber/ra-audit-log';
import { TreatmentEdit, TreatmentIcon, TreatmentList } from './pages/treatments';
import { TeamProfileCreate, TeamProfileIcon, TeamProfileList } from './pages/teams_profiles';
import { ProfileEdit, ProfileIcon, ProfileList } from './pages/profiles';
// const { provider_token } = JSON.parse(
//   localStorage.getItem(`sb-${supabaseConfig.projectRef}-auth-token`) || ""
// );

// const supabase = createClient(supabaseConfig.url, supabaseConfig.apiKey, {
//   auth: {
//     autoRefreshToken: true,
//     persistSession: true,
//   },
// });

const dataProviderWithFieldUpdates: DataProvider = {
    ...postgrestRestDataProviderWithRefresh,
    getList(resource, params) {
        return postgrestRestDataProviderWithRefresh.getList(resource, params)
    },
    create: async (resource, params) => {
        const { data } = params;
        const user = await authProvider.getIdentity().then(identity => identity.fullName)
        if (user) {
            data['createdby'] = user;
            data['createdate'] = new Date().toISOString();
            data['updatedby'] = user;
            data['lastupdate'] = new Date().toISOString();
        }
        console.info('dataProviderWithFieldUpdates - create ', {
            resource,
            data,
        });
        return postgrestRestDataProviderWithRefresh.create(resource, {
            data,
        });
    },
    update: async (resource, params) => {
        const { id, data, previousData } = params;
        const user = await authProvider.getIdentity().then(identity => identity.fullName)
        if (user) {
            data['updatedby'] = user;
            data['lastupdate'] = new Date().toISOString();
        }
        console.info('dataProviderWithFieldUpdates - update ', {
            resource,
            data,
            id,
        });
        return postgrestRestDataProviderWithRefresh.update(resource, {
            id,
            data,
            previousData,
        });
    },
    updateMany: async (resource, params) => {
        const { ids, data: dataOld } = params;
        let dataNew = {};
        const user = await authProvider.getIdentity().then(identity => identity.fullName)
        if (user) {
            dataNew = { ...dataOld }
            dataNew['updatedby'] = user;
            dataNew['lastupdate'] = new Date().toISOString();
        }
        console.info('dataProviderWithFieldUpdates - updateMany', {
            resource,
            dataOld,
            dataNew,
            ids,
            user,
        });
        return postgrestRestDataProviderWithRefresh.updateMany(resource, {
            ids,
            data: [dataNew],
        });
    },
    deleteMany(resource, params) {
        const { ids } = params;
        console.info('dataProviderWithFieldUpdates - deleteMany', {
            resource,
            ids,
        });
        return postgrestRestDataProviderWithRefresh.deleteMany(resource, { ids });
    },
};


const dataProviderWithEventsForMutations = addEventsForMutations(dataProviderWithFieldUpdates, authProvider, {
    name: 'events',
    resources: [
        'players',
        'teams_players',
        'reviews',
        'calls',
        'sprintvalues',
        'scoutings',
    ],
});

const App = () => {
    return (
        <MsalProvider instance={myMSALObj}>
            <Provider value={supabase}>
                <BrowserRouter>
                    <Admin
                        theme={ScpTheme}
                        loginPage={LoginPage}
                        authProvider={wrappedAuthProvider}
                        dataProvider={dataProviderWithEventsForMutations}
                        // dataProvider={dataProviderWithFieldUpdates}
                        i18nProvider={i18nProvider}
                        title="SCP Youngstars Admin"
                        requireAuth
                        layout={ScpLayout}
                    >
                        {permissions => (
                            <>
                                <Resource
                                    name="calendar"
                                    list={CalendarList}
                                    icon={CalendarIcon}
                                />
                                <Resource
                                    name="clubs"
                                    list={ClubList}
                                    edit={ClubEdit}
                                    create={ClubCreate}
                                    icon={ClubIcon}
                                    recordRepresentation={record =>
                                        `${record.name}`
                                    }
                                />
                                <Resource
                                    name="clubs"
                                    list={ClubList}
                                    edit={ClubEdit}
                                    create={ClubCreate}
                                    icon={ClubIcon}
                                    recordRepresentation={record =>
                                        `${record.name}`
                                    }
                                />
                                <Resource
                                    name="players"
                                    list={PlayerList}
                                    edit={PlayerEdit}
                                    create={PlayerCreate}
                                    icon={PlayerIcon}
                                />
                                <Resource
                                    name="seasons"
                                    list={SeasonList}
                                    edit={SeasonEdit}
                                    create={SeasonCreate}
                                    icon={SeasonIcon}
                                />
                                <Resource
                                    name="profiles"
                                    list={ProfileList}
                                    edit={ProfileEdit}
                                    icon={ProfileIcon}
                                />
                                <Resource
                                    name="teams_profiles"
                                    list={TeamProfileList}
                                    create={TeamProfileCreate}
                                    icon={TeamProfileIcon}
                                />
                                <Resource
                                    name="consultancies"
                                    list={ConsultancyList}
                                    edit={ConsultancyEdit}
                                    create={ConsultancyCreate}
                                    icon={ConsultancyIcon}
                                />
                                <Resource
                                    name="ageclasses"
                                    list={AgeclassList}
                                    edit={AgeclassEdit}
                                    create={AgeclassCreate}
                                    icon={AgeclassIcon}
                                />
                                <Resource
                                    name="repteams"
                                    list={RepteamList}
                                    edit={RepteamEdit}
                                    create={RepteamCreate}
                                    icon={RepteamIcon}
                                />
                                <Resource
                                    name="leagues"
                                    list={LeagueList}
                                    edit={LeagueEdit}
                                    create={LeagueCreate}
                                    icon={LeagueIcon}
                                />
                                <Resource
                                    name="positions"
                                    list={PositionList}
                                    edit={PositionEdit}
                                    create={PositionCreate}
                                    icon={PositionIcon}
                                />
                                <Resource name="all_notes" />
                                <Resource name="notes" />
                                <Resource
                                    name="texts"
                                    list={TextList}
                                    edit={TextEdit}
                                    create={TextCreate}
                                    show={TextShow}
                                    icon={TextIcon}
                                />
                                <Resource
                                    name="teams"
                                    list={TeamList}
                                    edit={TeamEdit}
                                    create={TeamCreate}
                                    icon={TeamIcon}
                                />
                                <Resource
                                    name="squadplayers"
                                    list={SquadplayerList}
                                    create={SquadplayerCreate}
                                    icon={SquadplayerIcon}
                                />
                                <Resource
                                    name="absences"
                                    list={AbsenceList}
                                    create={AbsenceCreate}
                                    icon={AbsenceIcon}
                                />
                                <Resource
                                    name="calls"
                                    list={CallList}
                                    create={CallCreate}
                                    edit={CallEdit}
                                    icon={CallIcon}
                                />
                                <Resource
                                    name="treatments"
                                    list={TreatmentList}
                                    edit={TreatmentEdit}
                                    icon={TreatmentIcon}
                                />
                                <Resource
                                    name="reviews"
                                    list={ReviewList}
                                    create={ReviewCreate}
                                    edit={ReviewEdit}
                                    icon={ReviewIcon}
                                />
                                <Resource
                                    name="scoutings"
                                    list={ScoutingList}
                                    create={ScoutingCreate}
                                    edit={ScoutingEdit}
                                    icon={ScoutingIcon}
                                />
                                <Resource
                                    name="sprintvalues"
                                    list={SprintvalueList}
                                    create={SprintvalueCreate}
                                    edit={SprintvalueEdit}
                                    icon={SprintvalueIcon}
                                />
                                <Resource
                                    name="transfers"
                                    list={TransferList}
                                    create={TransferCreate}
                                    edit={TransferEdit}
                                    icon={TransferIcon}
                                />
                                <Resource
                                    name="applications"
                                    list={ApplicationList}
                                    show={ApplicationShow}
                                    icon={ApplicationIcon}
                                />
                                <Resource
                                    name="physicals"
                                    list={PhysicalList}
                                    icon={PhysicalIcon}
                                />
                                <Resource
                                    name="dfbnet_emails"
                                    list={DfbnetEmailList}
                                    icon={DfbnetEmailIcon}
                                />
                                <Resource name="teams_players" />
                                <Resource
                                    name="coaches"
                                    list={CoachList}
                                    edit={CoachEdit}
                                    create={CoachCreate}
                                    icon={CoachIcon}
                                />
                                <Resource
                                    name="players"
                                    list={PlayerList}
                                    edit={PlayerEdit}
                                    create={PlayerCreate}
                                    icon={PlayerIcon}
                                />
                                <Resource
                                    name="matches"
                                    list={MatchList}
                                    edit={MatchEdit}
                                    create={MatchCreate}
                                    icon={MatchIcon}
                                />
                                <Resource
                                    name="practices"
                                    list={PracticeList}
                                    edit={PracticeEdit}
                                    create={PracticeCreate}
                                    icon={PracticeIcon}
                                />
                                <Resource
                                    name="trainings"
                                    list={TrainingList}
                                    edit={TrainingEdit}
                                    create={TrainingCreate}
                                    icon={TrainingIcon}
                                />
                                <Resource
                                    name="seasons"
                                    list={SeasonList}
                                    edit={SeasonEdit}
                                    create={SeasonCreate}
                                    icon={SeasonIcon}
                                />
                                <Resource
                                    name="ageclasses"
                                    list={AgeclassList}
                                    edit={AgeclassEdit}
                                    create={AgeclassCreate}
                                    icon={AgeclassIcon}
                                />
                                <Resource
                                    name="repteams"
                                    list={RepteamList}
                                    edit={RepteamEdit}
                                    create={RepteamCreate}
                                    icon={RepteamIcon}
                                />
                                <Resource
                                    name="leagues"
                                    list={LeagueList}
                                    edit={LeagueEdit}
                                    create={LeagueCreate}
                                    icon={LeagueIcon}
                                />
                                <Resource
                                    name="positions"
                                    list={PositionList}
                                    edit={PositionEdit}
                                    create={PositionCreate}
                                    icon={PositionIcon}
                                />
                                <Resource
                                    name="lineups"
                                    list={LineupList}
                                    edit={LineupEdit}
                                    create={LineupCreate}
                                    icon={LineupIcon}
                                />
                                <Resource
                                    name="texts"
                                    list={TextList}
                                    edit={TextEdit}
                                    create={TextCreate}
                                    show={TextShow}
                                    icon={TextIcon}
                                />
                                <Resource
                                    name="teams"
                                    list={TeamList}
                                    edit={TeamEdit}
                                    create={TeamCreate}
                                    icon={TeamIcon}
                                />
                                <Resource
                                    name="squadplayers"
                                    list={SquadplayerList}
                                    create={SquadplayerCreate}
                                    icon={SquadplayerIcon}
                                />
                                <Resource name="teams_players" />
                                <CustomRoutes>
                                    <Route path="/" element={<Home />} />
                                </CustomRoutes>
                                <CustomRoutes noLayout>
                                    <Route
                                        path="/custom"
                                        element={
                                            <CustomRouteNoLayout title="Posts from /custom" />
                                        }
                                    />
                                </CustomRoutes>
                            </>
                        )}
                    </Admin>
                </BrowserRouter>
            </Provider>
        </MsalProvider>
    );
};
export default App;
