import React, { useCallback, useState } from "react";
import Button from "@mui/material/Button";
import AddSquadplayersIcons from "@mui/icons-material/GroupAdd";
import { useLocation } from "react-router";
import {
  useTranslate,
  useNotify,
  useDataProvider,
  useRefresh,
} from "react-admin";
import { getAbsencesByTeam } from "../../../types";

export const AddMatchPlayersButton: React.FC<{
  record: any;
}> = (props) => {
  const {
    record: { team_id, date: matchDate },
  } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const [submitting, setSubmitting] = useState(false);
  const location = useLocation();
  const [, , match_id] = location.pathname.split("/");
  const dataProvider = useDataProvider();
  const handleSubmit = useCallback(
    async () => {
      setSubmitting(true);
      const { data: squadplayers, total } = await dataProvider.getList(
        "squadplayers",
        {
          pagination: { page: 1, perPage: 50 },
          sort: { field: "name", order: "ASC" },
          filter: {
            team_id: team_id,
            scoutingstatus: "member",
          },
        }
      );
      console.log(`found ${total} squadplayers`);
      const filtered = await getAbsencesByTeam(
        dataProvider,
        team_id,
        matchDate
      );
      const squadPlayerPromises = squadplayers.map((squadplayer) => {
        const { id, player_id, name } = squadplayer;
        // Testspieler Apple u. Staff-Member
        const present = (player_id === '9595dc98-c5e9-48c9-a8ef-9b50bc6ebb44' || player_id === '3a3b6ec2-e60b-4b3c-8147-35a6393af5ed') ? 0 : 1;
        const data = {
          name: name,
          team_id: team_id,
          player_id: player_id,
          squadplayer_id: id,
          match_id: match_id,
          present: present,
        };
        if (filtered[player_id]) {
          console.log("adding absence", filtered[player_id]);
          data[filtered[player_id].key] = filtered[player_id].value;
          data["present"] = 0;
        }
        return dataProvider.create("matches_players", {
          data,
        });
      });
      await Promise.all(squadPlayerPromises);
      refresh();
      notify("resources.matches.notification.squadplayers_added", {
        type: "info",
        undoable: false,
      });
    },
    [dataProvider, team_id, matchDate] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Button onClick={handleSubmit} disabled={submitting}>
      <AddSquadplayersIcons />
      {translate("resources.matches.actions.squadplayers_add")}
    </Button>
  );
};
