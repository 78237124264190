import React, { Fragment } from 'react';
import {
    Datagrid,
    DeleteButton,
    FormDataConsumer,
    FormTab,
    FunctionField,
    Link,
    ReferenceManyField,
    useGetOne,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { MatchPlayerStatusField } from '../buttons/MatchPlayerStatusField';
import { MatchPlayerStatusEditButton } from '../buttons/MatchPlayerStatusEditButton';
import { AddMatchPlayersButton } from '../buttons/AddMatchPlayersButton';
import { DeleteMatchPlayersButton } from '../buttons/DeleteMatchPlayersButton';
import { get } from 'lodash';
import { AddPlayerButton } from '../buttons/AddPlayerButton';
import { MatchPlayerTransportNeedField } from '../buttons/MatchPlayerTransportNeededField';

export const MatchesPlayersFormTab: React.FC = props => {
    const location = useLocation();
    const [, , match_id] = location.pathname.split('/');
    const { data: match, isLoading } = useGetOne('matches', { id: match_id });
    if (isLoading) return null;
    return (
        <FormTab
            label="resources.matches.tabs.matches_players"
            path="matches_players"
            sx={{
                '& .RaDatagrid-headerCell': {
                    padding: '4px 0px 4px 0',
                    textAlign: 'center',
                },
            }}
            {...props}
        >
            <ReferenceManyField
                sort={{ field: 'name', order: 'ASC' }}
                // filter={{ 'player_id@neq': '3a3b6ec2-e60b-4b3c-8147-35a6393af5ed' }}
                perPage={50}
                reference="matches_players"
                source="id"
                target="match_id"
            >
                <Datagrid>
                    <FunctionField
                        label="resources.matches.fields.name"
                        source="name"
                        render={record => (
                            <Link to={`/players/${get(record, 'player_id')}/2`}>
                                {get(record, 'name')}
                            </Link>
                        )}
                    />
                    <MatchPlayerStatusField
                        label="resources.matches.fields.present"
                        source="present"
                        match={match}
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.away"
                        source="away"
                        match={match}
                        inputType="text"
                    />
                    <MatchPlayerStatusField
                        label="resources.matches.fields.ill"
                        source="ill"
                        match={match}
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.injured"
                        source="injured"
                        match={match}
                        inputType="text"
                    />
                    <MatchPlayerStatusField
                        label="resources.matches.fields.starting"
                        source="starting"
                        match={match}
                    />
                    <MatchPlayerTransportNeedField
                        label="resources.matches.fields.transport_needed"
                        source="transport_needed"
                        match={match}
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.task"
                        source="task"
                        match={match}
                        inputType="text"
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.rating"
                        source="rating"
                        match={match}
                        inputType="number"
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.substituted_out"
                        source="substituted_out"
                        match={match}
                        inputType="number"
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.substituted_in"
                        source="substituted_in"
                        match={match}
                        inputType="number"
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.time_played"
                        source="time_played"
                        disabled={true}
                        match={match}
                        inputType="number"
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.assists"
                        source="assists"
                        match={match}
                        inputType="number"
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.goals"
                        source="goals"
                        match={match}
                        inputType="number"
                    />
                    <MatchPlayerStatusEditButton
                        label="resources.matches.fields.yellow"
                        source="yellow"
                        match={match}
                        inputType="number"
                    />
                    <DeleteButton
                        mutationMode="optimistic"
                        resource="matches_players"
                        redirect={`/matches/${match_id}/2`}
                        label=""
                    />
                </Datagrid>
            </ReferenceManyField>
            <FormDataConsumer subscription={{ values: true }}>
                {({ formData }) =>
                    formData.team_id && (
                        <Fragment>
                            <AddMatchPlayersButton
                                record={formData}
                                {...props}
                            />
                            <DeleteMatchPlayersButton
                                record={formData}
                                {...props}
                            />
                            <AddPlayerButton
                                type="matches_players"
                                {...props}
                            />
                        </Fragment>
                    )
                }
            </FormDataConsumer>
        </FormTab>
    );
};
