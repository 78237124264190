/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {
  Datagrid,
  List,
  EditButton,
  usePermissions,
  Filter,
  TextInput,
  TextField,
  ReferenceField,
  FunctionField,
  useTranslate,
  SelectInput,
  useGetIdentity,
  DeleteButton,
  DateField,
  BooleanField,
} from 'react-admin';
import { choicesCoachType, choiceCoachLicenses } from '../../types';

const ProfileFilter: React.FC = props => (
  <Filter {...props}>
    <TextInput
      label="resources.profiles.fields.full_name"
      source="full_name@ilike"
      alwaysOn
    />
  </Filter>
);

export const ProfileList = props => {
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
  if (isLoading || !allowedAccess) return null;
  return (
    <List {...props}
      filters={<ProfileFilter />}
      sort={{ field: 'id', order: 'ASC' }}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="email" />
        <TextField source="full_name" />
        <ReferenceField label="resources.profiles.fields.player_id" source="player_id" reference="players">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="resources.profiles.fields.push_enabled" source="push_enabled" />
        <TextField label="resources.profiles.fields.admin_push_enabled" source="admin_push_enabled" />
        <TextField label="resources.profiles.fields.updatedby" source="updatedby" />
        <DateField label="resources.profiles.fields.updated_at" source="updated_at"
          locales="de-DE"
          options={{ year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'Europe/Berlin' }} />
        <DeleteButton label="" />
      </Datagrid>
    </List>
  );
};
