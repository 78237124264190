/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {
  Datagrid,
  List,
  EditButton,
  usePermissions,
  Filter,
  TextInput,
  TextField,
  ReferenceField,
  FunctionField,
  useTranslate,
  SelectInput,
  useGetIdentity,
  DeleteButton,
  DateField,
} from 'react-admin';
import { choicesCoachType, choiceCoachLicenses } from '../../types';

const ProfileFilter: React.FC = props => (
  <Filter {...props}>
    <TextInput
      label="resources.teams_profiles.fields.profile_name"
      source="profile_name@ilike"
      alwaysOn
    />
  </Filter>
);

export const TeamProfileList = props => {
  const {
    isLoading,
    data: { roles },
  } = useGetIdentity();
  const allowedAccess = roles && roles.includes(`scpyoungstars_mgmt`);
  if (isLoading || !allowedAccess) return null;
  return (
    <List {...props} filters={<ProfileFilter />} sort={{ field: 'id', order: 'ASC' }}>
      <Datagrid>
        <ReferenceField label="resources.teams_profiles.fields.profile_id" source="profile_id" reference="profiles">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField label="resources.teams_profiles.fields.team_id" source="team_id" reference="teams">
          <TextField source="name" />
        </ReferenceField>
        <TextField label="resources.teams_profiles.fields.createdby" source="createdby" />
        <DateField label="resources.teams_profiles.fields.createdate" source="createdate" />
        <DeleteButton label="" />
      </Datagrid>
    </List>
  );
};
